'use client';
import Image from 'next/image';
import { CloseIcon } from '../icons';
import { SubMenuChannelList } from '../side-menu';
import { useSetChannel } from '@/hooks/useSetChannel';
import { MobileMenuChannelList } from '../side-menu/mobile';
import { useEffect, useState } from 'react';
import { CHANNEL_NAME_COOKIE_KEY, defaultChannel } from '@/i18n/config';
import { useCookies } from 'react-cookie';
import { useTranslations } from 'next-intl';
import { useGetChannelPref } from '@/providers/ChannelProvider';

export default function LanguageSelectionModalMainWrapper() {
  const t = useTranslations();
  const channel = useGetChannelPref();

  const [isSelected, setIsSelected] = useState(true);
  useEffect(() => {
    // Display language channel selection modal with 2 seconds delay
    const timeout = setTimeout(() => {
      setIsSelected(false);
    }, 2000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);
  const [cookies] = useCookies([CHANNEL_NAME_COOKIE_KEY]);
  const setChannel = useSetChannel();

  const onClickProceedWorld = () => {
    setChannel(channel || defaultChannel);
    setIsSelected(true);
  };

  if (isSelected || cookies[CHANNEL_NAME_COOKIE_KEY]) return null;

  return (
    <div
      className="fixed w-full h-full top-0 z-[100] flex justify-center items-center"
      style={{ background: 'rgba(0, 0, 0, 0.16)' }}
    >
      <div className="xl:gap-0 gap-6 xl:p-12 xl:pb-0 lg:px-8 lg:pt-6 md:px-6 md:pt-12 pt-4 px-4 pb-0 sm:mx-auto mx-2 bg-grey-1 rounded-custom flex flex-col justify-center items-center xl:min-w-languageSelectionModalContentXlW lg:min-w-languageSelectionModalContentLgW md:min-w-languageSelectionModalContentMdW max-w-languageSelectionModalContentW">
        <div className="flex justify-center items-start w-full xl:mb-6">
          <Image
            src="/svg/primary-dots.svg"
            alt="trt-primary"
            width={85}
            height={72}
            className="mx-auto xl:w-[85px] xl:h-[72px]"
            quality={80}
          />
          <CloseIcon
            onClick={onClickProceedWorld}
            className="cursor-pointer"
            width={24}
            height={24}
          />
        </div>

        <div className="text-center text-grey-11 font-playfair xl:text-3.5xl xl:leading-10 sm:text-2xl sm:leading-7 text-xl leading-custom_5 font-medium xl:mb-4">
          {t('channelModalTitle')}
        </div>

        <div className="flex flex-col gap-2 text-[#5A5A5A] font-notosans xl:text-base text-sm leading-normal text-center xl:mb-10">
          {t('channelModalDesc')}
        </div>

        <div className="flex flex-row gap-2.5 xl:mb-10">
          <button
            onClick={onClickProceedWorld}
            className="bg-primary-4 px-5 h-12 text-grey-1 rounded-custom flex justify-center items-center font-notosans text-sm leading-6.5 font-bold"
          >
            {t('channelModalDefault')}
          </button>
        </div>

        <div className="relative w-full xl:h-[390px] lg:h-[300px] md:h-[525px] h-[300px] overflow-scroll mb-[1px]">
          <div className="block sm:hidden">
            <MobileMenuChannelList
              onCloseMenu={() => setIsSelected(true)}
              mainWrapperClassName="p-2.5 pt-4 gap-2 grid sm:hidden"
            />
          </div>
          <div className="hidden sm:block">
            <SubMenuChannelList
              onCloseMenu={() => setIsSelected(true)}
              mainWrapperClassName="xl:px-8 sm:px-6 px-2.5 rounded-custom_4"
              channelsWrapperClassName="xl:grid-cols-4 md:grid-cols-3 grid-cols-2 pb-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
